<template>
  <b-row :class="is_vanity_user ? '' : 'border-top'">
    <b-col class="col-12">
      <b-card
        v-if="(user_data_session && user_data_session.meta_info && user_data_session.meta_info.vanity_name === $route.params.user) || !user_data_session" 
        class="alert-pop" 
        @click="user_data_session !== null ? redirectSocialNetwork() : openModalLogin()"
      >
  
        <b-card-text class="title-pop"> {{ user_data_session ? $t('creator.publicVersion') : $t('creator.profileNoLoginText1')}} </b-card-text>
        <div class="d-flex justify-content-between flex-wrap">
          <b-card-text class="subtitle-pop avenir-medium"> {{ user_data_session ? $t('creator.socialTab') : $t('creator.profileNoLoginText2')}}</b-card-text>
          <b-button class="btn-go col-12 col-sm-auto" variant="btn-go">{{user_data_session ? $t('campaigns.goProfile') : $t('login_text')}}</b-button>
        </div>
      </b-card>
    </b-col>
    <b-col class="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-2 pt-3 mb-3">
      <div class="d-flex justify-content-center flex-wrap border-bottom pb-3">
        <b-avatar :src="getAssetUrl(profile.profile_image)" size="130px"></b-avatar>
        <strong class="w-100 h2 container_email">{{profile.first_name}} {{ profile.last_name }}</strong>
        <p class="text-muted m-0">{{getFormat(getSumFollowers())}}</p>
        <p class="followers-text-under">{{ $t('creator.susbscribers/') }}</p>
        
        <b-avatar-group size="20px" :overlap="profile.networks.length <= 2 ? '0.1' : '0.6'" class="d-flex justify-content-center col-12">
          <b-avatar v-for="(network, index) in profile.networks" :key="index"
            target="_blank"
            :href="getLinkNetwork(network)"
            variant="ligth"
          >
          <b-img :src="getIconWhite(network.network)" :class="getIconColor(network.network, false)" fluid></b-img>
          </b-avatar>
        </b-avatar-group>
      </div>
      <div class="d-flex justify-content-between flex-wrap mt-3 mb-1">
        <span class="d-flex text-data">{{$t('question_text')}}</span> 
        <b-link @click="$router.push({ name: 'setting-public-profile', params: {section: 'profile'}})" class="d-flex text-edit" v-if="!is_vanity_user && profile.meta_info">{{ $t('edit') }}</b-link>
      </div>
      <span class="data-user">{{ profile.question_text }}</span>
      <hr class="mt-2"/>
      <div class="d-flex justify-content-between flex-wrap mt-3 mb-1">
        <span class="d-flex text-data">{{$t('dashboard.location')}}</span> 
        <b-link @click="$router.push({ name: 'setting-public-profile', params: {section: 'basic_info'}})" class="d-flex text-edit" v-if="!is_vanity_user && profile.meta_info">{{ $t('edit') }}</b-link>
      </div>
      <span class="d-flex align-items-center data-user">
          <country-flag-svg
            v-if="this.profile.country"
            class="country-user"
            :country="this.profile.country"
          />
          {{ this.profile.country }}
      </span>
    </b-col>

     <b-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-7">
      <div class="card-analytics-profile-net p-3 border-left border-right">
        <no-Results v-if="!profile.meta_info"
            :title="$t('dashboard.noResults')"
            :subtitle="$t('dashboard.noResultsSubtitle')"
            :text_button="$t('dashboard.createProfile')"
            :btn_action="createProfile"
          />
        <div v-if="is_vanity_user && profile.meta_info && !isYourOwnProfile()" class="w-100 d-flex justify-content-end">
          <b-button v-if="hasCompatibleNetwork() && !this.permission_metrics.already_viewed" class="button-max-views col-12 col-md-auto" @click="user_data_session !== null ? buyReport() : openModalLogin()">{{ $t('creator.buyReportCompleted') }}</b-button></div>
        <div class="d-flex justify-content-between flex-wrap mt-3 mb-1">
        <span v-if="profile.meta_info" class="d-flex text-title">{{$t('creator.titleDemographics')}}</span>
        <b-dropdown v-if="!is_vanity_user && profile.meta_info" variant="link" right toggle-class="p-0" no-caret>
          <template #button-content>
            <span>{{ $t('edit') }}</span>
          </template>
          <b-dropdown-item class="force-item-color" @click="redirectToStep('profile')">{{$t('dashboard.profile')}}</b-dropdown-item>
          <b-dropdown-item class="force-item-color" @click="redirectToStep('basic_info')">{{$t('dashboard.basicInfo')}}</b-dropdown-item>
          <b-dropdown-item class="force-item-color" @click="redirectToStep('demographics')">{{$t('dashboard.demographicsStep')}}</b-dropdown-item>
          <b-dropdown-item class="force-item-color" @click="redirectToStep('family')">{{$t('dashboard.family')}}</b-dropdown-item>
          <b-dropdown-item class="force-item-color" @click="redirectToStep('vanity')">{{$t('dashboard.vanityURL')}}</b-dropdown-item>
          <b-dropdown-divider/>
          <b-dropdown-item class="force-item-color" @click="redirectToStep('connections')">{{$t('dashboard.editAllSteps')}}</b-dropdown-item>
        </b-dropdown>
      </div>
        
        <b-row v-if="profile.meta_info">
         <div class="mb-1 col-12 col-sm-6">
          <span class="d-flex info-data">{{$t('dashboard.gender')}}</span>
          <span class="d-flex">{{gender ? $t('creator.gender').find((i) => i.value === gender).text : '--' }}</span>
        </div>
        <div class="col-12 col-sm-6 mb-1">
          <span class="d-flex info-data">{{$t('dashboard.age')}}</span>
          <span class="d-flex">{{ age ? age : '--' }}</span>
        </div> 
        <div class="col-12 col-sm-6 mb-1">
          <span class="d-flex info-data">{{$t('dashboard.ethnicity')}}</span>
          <span class="d-flex">{{ethnicity ? getTraduction(ethnicity,'ethnicity' ) : '--' }}</span>
        </div>
        <div class="col-12 col-sm-6 mb-1">
          <span class="d-flex info-data">{{$t('dashboard.relationship')}}</span>
          <span class="d-flex">{{relationship ? $t('creator.relationship').find((i) => i.value === relationship).text : '--' }}</span>
        </div>
        <div class="col-12 col-sm-6 mb-1">
          <span class="d-flex info-data">{{$t('dashboard.education')}}</span>
          <span class="d-flex">{{scholarship ? $t('creator.scholarship').find((i) => i.value === scholarship).text : '--' }}</span>
        </div>
        <div class="col-12 col-sm-6 mb-1">
          <span class="d-flex info-data">{{$t('dashboard.language')}}</span> 
          <span  class="d-flex">{{languages ? getTraduction(languages, 'languages') : '--' }} </span>
        </div>
        <div class="col-12 col-sm-6 mb-1">
          <span class="d-flex info-data">{{$t('dashboard.pets')}}</span>
          <span class="d-flex">{{pets ? getTraduction( pets, 'pets') : '--'}}</span>
        </div>
        <div class="col-12 col-sm-6 mb-1">
          <span class="d-flex info-data">{{$t('dashboard.children')}}</span>
          <span class="d-flex">{{children ? getChildrenTraduction(children) : '--'}}</span>
        </div>
      </b-row>
      <hr/>
        <span class="pb-1 text-title">Analytics</span>
        <b-row class="mt-1">
          <b-col class="col-12 mb-1 col-lg-6">
            <b-tooltip v-if="this.profile.networks.length === 0" target="tooltip-er">{{ $t('creator.noConnect') }}</b-tooltip>
            <div class="border-box-analytics p-2" id="tooltip-er">
              <p>ENG. RATE</p>
              <strong v-if="this.permission_metrics.already_viewed || this.profile.networks.length === 0">{{er_calculate > 0 ? `${decimalFixed(er_calculate, 2)} %` : '--'}}</strong>
              <b-icon v-else icon="lock"/>
            </div>
          </b-col>
          <b-col class="col-12 mb-1 col-lg-6">
            <b-tooltip v-if="this.profile.networks.length === 0" target="tooltip-er-post">{{ $t('creator.noConnect') }}</b-tooltip>
            <div class="border-box-analytics p-2" id="tooltip-er-post">
              <p>{{$t('creator.avg_er_post')}}</p>
              <strong v-if="this.permission_metrics.already_viewed || this.profile.networks.length === 0">{{avg_per_post > 0 ? getFormat(avg_per_post) : 'N/A'}}</strong>
              <b-icon v-else icon="lock"/>
            </div>
          </b-col>
        </b-row>
        <hr class="my-2"/>
        <div>
          <span class="text-title">{{ $t('dashboard.proposals') }}</span>
        </div>
        <b-col class="p-0">
          <vue-horizontal responsive class="horizontal my-2" :displacement="0.3" v-if="loaded_profile">
            <card-category
              v-for="(item, index) in proposals_published()" 
              :key="index"
              :redirect="true"
              :proposal="item"
              :width="'300px'"
              :own_profile="profile.profile_image"
              :countries="countries"
              @open_modal_login="openModalLogin"
              class="mr-1"
            />
          </vue-horizontal>
          <div v-if="proposals_published().length === 0" class="d-flex justify-content-center flex-column">
            <no-Results
              :title= "isYourOwnProfile() ? $t('creator.no_results_proposals_auth') : $t('creator.no_results_proposals')"
              :show_button= "false"
            />
          </div>
        </b-col>
     </div>
    </b-col>

    <div class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 position-relative">
      <view-connections-card 
        :networks_dad="profile.networks" 
        class="p-2" v-if="loaded_profile"
        @open_modal_login="openModalLogin"
        @delete_network="deleteNetwork"
        @updated_network_edit="updateNetwork"
      ></view-connections-card>
    </div>
    <div class="container-loader-searcher loader-table-cards" v-if="!loaded_profile">
      <b-spinner class="spinner-table-searcher"></b-spinner>
    </div>
    <modal-login :open_modal="open_modal_login" :key="update_modal_login"></modal-login>
  </b-row>
</template>

<script>
import {
  BButton,
  BLink,
  BRow,
  BCol,
  BAvatarGroup,
  BAvatar,
  BDropdown,
  BDropdownItem,
  BDropdownDivider,
  BSpinner,
  BImg,
  BCard,
  BCardText,
  BTooltip
} from 'bootstrap-vue';
import service_others from "@/services/others";
import profile_services from '@/services/profile';
import viewConnectionsCard from '@/views/components/proposals/viewConnectionsCard.vue';
import VueHorizontal from 'vue-horizontal';
import cardCategory from "@/views/pages/proposals/cardCategory.vue";
import noResults from '@/views/pages/proposals/stepper/noResults.vue';
import countryFlagSvg from '@/views/components/countryFlagSvg.vue';
import ModalLogin from '@/views/components/modal/ModalLogin.vue';
import { getIconWhite, getIconColor } from '@/libs/utils/icons'
import { getURL, getAssetUrl } from '@/libs/utils/urls';
import { getFormat, decimalFixed } from '@/libs/utils/formats';
import { getUserData, setuserData } from '@/libs/utils/user';
import { getCountries } from '@/libs/utils/others';

export default {
  name: 'profileNetworks',
  components: {
    BButton,
    BCard,
    BCardText,
    countryFlagSvg,
    noResults,
    cardCategory,
    VueHorizontal,
    BLink,
    BRow,
    BCol,
    BAvatarGroup,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BSpinner,
    viewConnectionsCard,
    ModalLogin,
    BImg,
    BTooltip
  },
  data() {
    return {
      permission_metrics: {
        already_viewed: false 
      },
      decimalFixed,
      setuserData,
      getUserData,
      getFormat,
      getURL,
      getAssetUrl,
      getIconWhite,
      getIconColor,
      countries: getCountries(),
      proposals: [],
      profile: {
        country: "",
        networks: [],
      },
      gender: '',
      age: '',
      ethnicity: '',
      relationship: '',
      scholarship: '',
      languages: '',
      pets: '',
      children: '',
      loaded_profile: false,
      open_modal_login: false,
      update_modal_login: false,
      er_calculate: 0,
      avg_per_post: 0,
      loaded_proposals: false,
      own_user: null,
      valitate_networks: ["instagram", "tiktok", "youtube", "twitch", "twitter"]
    }
  },
  metaInfo() {
    return {
      meta: [
        { vmid: "og:image",  property: 'og:image', content: getAssetUrl(this.profile.profile_image) },
        { vmid: "twitter:image", name: 'twitter:image', content: getAssetUrl(this.profile.profile_image) },
      ]
    };
  },
  created() {
    if (this.is_vanity_user) {
      this.getProfileByVanity();
      if (this.user_data_session === null) this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);
    } else {
      this.getOwnProfile();
    }
    this.getSumFollowers()
  },
  destroyed() {
    if (this.user_data_session === null)  this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', false);
  },
  computed: {
    is_vanity_user() {
      return this.$route.params.user;
    },
    user_data_session() {
      return getUserData();
    },
  },
  methods: {
    checkView() {
      if (this.first_uuid) {
        profile_services.canUnlockOrPayProfile(this.first_uuid).then((response) => {
          this.permission_metrics = response;
        });
      }
    },
    hasCompatibleNetwork() {
      return !!this.profile.networks.find(network => this.valitate_networks.includes(network.network));
    },
    buyReport() {
      profile_services.buyReport(this.first_uuid).then((response) => {
        window.location.href = response.url;
      });
    },
    isYourOwnProfile() {
      const user_data = getUserData();
      if (user_data && this.own_user) return this.own_user.id === user_data.id;
      else return false
    },
    deleteNetwork(network_uuid) {
      this.profile.networks = this.profile.networks.filter((net) => net.uuid !== network_uuid)
    },
    updateNetwork(network) {
      this.profile.networks = this.profile.networks.map((i) => {
        if (i.uuid === network.uuid) {
          return { ...network };
        }
        return i;
      });
    },
    redirectToStep(sectionStep) {
      this.$router.push({ name: 'setting-public-profile', params: { section: sectionStep, is_edit: true } });
    },
    openModalLogin() {
      this.open_modal_login = true;
      this.update_modal_login = !this.update_modal_login;
    },
    redirectSocialNetwork() {
      this.$router.push({name: 'my_work', params: { section: 'networks' } })
    },
    createProfile() {
      this.$router.push({ name: 'setting-public-profile', params: {section: 'connections'}})
    },
    getProfileByVanity() {
      service_others.getProfileByVanityName(this.is_vanity_user).then((response) => {
        if (response.status >= 400) this.$router.push({name: 'dashboard-analytics'})
        this.loaded_profile = true;
        this.own_user = response.user;
        this.proposals = response.proposals
        this.loaded_proposals = true;
        this.assignDataProfile(response);
        if (this.user_data_session) {
          this.checkView()
        }

      });
    },
    isSuperUser() {
      return getUserData() ? getUserData().is_superuser : false
    },
    getOwnProfile() {
      const user = JSON.parse(localStorage.userData);
      service_others.getProfile().then((response) => {
        this.loaded_profile = true;
        this.own_user = response.user;
        this.proposals = response.user.proposals
        this.assignDataProfile(response);
        setuserData(response, user.ability);
        this.checkView()
      });
    },
    assignDataProfile(response) {
      this.profile = response.user;
      this.first_uuid = this.profile.networks.find(network => this.valitate_networks.includes(network.network))?.uuid;
      this.gender = this.profile.meta_info && this.profile.meta_info.gender ? this.profile.meta_info.gender : '';
      this.age = this.profile.meta_info && this.profile.meta_info.age ? this.profile.meta_info.age : '';
      this.ethnicity = this.profile.meta_info && this.profile.meta_info.ethnicity ? this.profile.meta_info.ethnicity : '';
      this.relationship = this.profile.meta_info && this.profile.meta_info.relationship ? this.profile.meta_info.relationship : '';
      this.scholarship = this.profile.meta_info && this.profile.meta_info.scholarship ? this.profile.meta_info.scholarship : '';
      this.languages = this.profile.meta_info && this.profile.meta_info.languages ? this.profile.meta_info.languages : '';
      this.pets = this.profile.meta_info && this.profile.meta_info.pets ? this.profile.meta_info.pets : '';
      this.children = this.profile.meta_info && this.profile.meta_info.children ? this.profile.meta_info.children : '';
    },
    getTraduction(values, type) {
      const arr = [];
      values.map((i) => {
        arr.push(this.$t(`creator.${type}`).find((v) => v.value === i).text);
      });
      return arr.join(', ');
    },
    getChildrenTraduction(children) {
      let arra = [];
      children.map((i) => {
        const objj = []
        for (const g in i) {
          objj.push(this.$t(`creator.${g}`).find((v) => v.value === i[g]).text)
        }
        arra = arra.concat(objj.join(': '))
      });
      return arra.join(', ');
    },
    getSumFollowers() {
      let sum = 0;
      let valid_er = 0
      let er_total = 0

      let total_avg_per_post = 0
      let valid_avg_per_post = 0

      this.profile.networks.forEach((i) => {
        if (parseFloat(i.er) > 0) {
          er_total += parseFloat(i.er)
          valid_er++
        }

        if (parseInt(i.avg_per_post) > 0) {
          total_avg_per_post += i.avg_per_post
          valid_avg_per_post++
        }
        sum += i.followers;
      });
      if (valid_er) this.er_calculate = er_total / valid_er

      if (valid_avg_per_post) this.avg_per_post = parseInt(total_avg_per_post / valid_avg_per_post)
      return sum;
    },
    proposals_published() {
      return this.proposals.length > 0 ? this.proposals.filter((item) => item.state === 'published') : []
    },
    getLinkNetwork(profile) {
      if (profile.profile_url) return profile.profile_url
      else if (profile.network === 'facebook' && profile.link) return profile.link
      return getURL(profile.network, profile.username, profile.is_channel)
    },
  }
}
</script>

<style scoped lang="scss">
.followers-text-under {
  display: block;
  width: 100%;
  text-align: center;
  font-family: 'avenir-medium';
  font-size: 13px;
  color: gray;
}
.horizontal >>> .v-hl-btn svg {
  border-radius: 0 !important;
  margin: 0 !important;
  padding: 8px !important;
  height: 100% !important;
  box-shadow: none !important;
  background: none !important;
}

.horizontal >>> .v-hl-btn-prev {
  background: linear-gradient(to left, #ffffff00 0, white 66%, white) !important;
  padding-right: 24px !important;
}

.horizontal >>> .v-hl-btn-next {
  background: linear-gradient(to right, #ffffff00 0, white 66%, white) !important;
  padding-left: 24px !important;
}
.horizontal >>> .v-hl-btn {
  top: 0 !important;
  bottom: 0 !important;
  transform: translateX(0) !important;
}
.container_email{
  word-break: break-all;
  margin-top: 1em;
  text-align: center;
}
.btn-go {
  background-color: #fff;
  border-color: #ced4da;
}
.container-loader-searcher {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255);
  z-index: 1000;
  right: 5px;
}
.loader-table-cards {
  z-index: 10;
  display: flex;
  justify-content: center;
}
.spinner-table-searcher {
  margin-top: 14em;
  height: 6em;
  width: 6em;
  color: #acacac;
}
.border-card-results {
  border-color: rgba(222,226,230,.7) !important;
  justify-content: center !important;
  text-align: center !important;
  align-items: center !important;
}
.subtitle-results {
  color: #495057;
}
.title-results {
  color: #212529;
  font-weight: bold;
  justify-content: center !important;
}
.button-go {
  margin-top: 10px;
  border-color: rgba(222,226,230,.7);
  background: transparent;
  color: #495057;
  font-weight: 400;
}
.button-go:hover {
  margin-top: 10px;
  border-color: rgba(222,226,230,.7);
  background: transparent;
  color: #212529 !important;
  font-weight: 400;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 14px 0px;
}
.avatar-icon {
  width: 30px !important;
  height: 30px;
  margin-left: 10px;
}
.info-data {
  margin-bottom: 0.25rem !important;
  text-transform: uppercase;
  font-size: 12px;
  margin-top: 10px;
  color: #6c757d;
}
.text-title {
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.2;
}
.country-user {
  margin-right: 10px;
}
.text-edit {
  color: #55a6c4;
}
.text-data {
  font-weight: bold !important;
  color: #495057;
}
.data-user {
  margin-top: 15px;
  color: #6c757d;
}
.border-box-analytics {
  border: 1px solid #eaeaea;
  border-radius: 1em;
  padding: 0.5em;
  box-shadow: 0rem 0.375rem 0.75rem rgb(108 117 125 / 8%);

  p {
    font-family: "avenir-medium";
    font-size: 11.5px;
    color: #6c757d;
  }
}
.avatar-network-work {
  padding: 0.2em;
  background-color: white !important;
}
.avatar-network-work:hover {
  width: 2rem !important;
  height: 2rem !important;
  background-color: white !important;
}
.avatar-network-work:focus {
  background-color: white !important;
}
.card-analytics-profile-net {
  height: 100%;
  background-color: white;
}
.alert-pop {
  background: linear-gradient(to left bottom, #7cd6af, #3e7ba0);
  cursor: pointer;
}
.title-pop {
  color: white;
  font-weight: bold;
}
.subtitle-pop {
  color: white;
}
</style>